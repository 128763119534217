<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.parameters.createModal.title") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <form-input
            v-model="category.description"
            label="page.parameters.createModal.nameLabel"
            :placeholder="$t('page.parameters.createModal.namePlacehoder')"
            veeName="page.parameters.createModal.nameLabel"
            :veeRules="{ required: true }"
          />
          <base-switcher
            v-model="category.active"
            name="status-switch"
            switchLabel="page.parameters.createModal.status"
          />
          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button variant="outline-dark" class="mr-3" @click="closeModal">
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="createCategory"
            >
              {{ $t("button.insert") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "./../../../../console-components/BaseFormInput";
import BaseSwitcher from "./../Base/Switcher.vue";

export default {
  props: {
    state: { type: Boolean, default: false },
    callbackAfterCreate: { type: Function, default: () => {} },
    defaultCategory: { type: Object, required: false }
  },
  components: { FormInput, BaseSwitcher },
  data() {
    return {
      category: {
        description: "",
        active: false
      }
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    }
  },
  methods: {
    resetForm() {
      if (this.defaultCategory) {
        this.category = Object.assign({}, this.defaultCategory);
      } else {
        this.category = {
          description: "",
          active: false
        };
      }
    },
    async createCategory() {
      if (this.defaultCategory) {
        try {
          this.$store.commit("loader/SET_LOADER", {});
          await this.$store.dispatch("parameters/updateCategory", {
            issueCategoryId: this.defaultCategory._id,
            payload: this.getCategoryPayload()
          });
          await this.callbackAfterCreate();
          this.closeModal();
        } catch (error) {
          console.log(error);
        } finally {
          this.$store.commit("loader/RESET");
        }
      } else {
        try {
          this.$store.commit("loader/SET_LOADER", {});
          await this.$store.dispatch(
            "parameters/createCategory",
            this.getCategoryPayload()
          );
          await this.callbackAfterCreate();
          this.closeModal();
        } catch (error) {
          console.log(error);
        } finally {
          this.$store.commit("loader/RESET");
        }
      }
    },
    getCategoryPayload() {
      const categoryDetails = {
        description: this.category.description,
        active: this.category.active
      };
      return categoryDetails;
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped></style>
