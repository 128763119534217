<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.parameters.title") }}</h3>
    <file-uploader ref="parameterLogo" :multiple="false" @setFiles="setLogo" />
    <div class="font-weight-bold mb-3">
      {{ $t("page.parameters.addLogo") }}
    </div>
    <div v-if="invalidImage" class="mb-3 text-red">
      {{ $t("page.parameters.invalidImageMessage") }}
    </div>
    <div class="no-gutters image-container">
      <img
        v-if="!parameters.logo"
        class="clickable mr-2"
        style="width: 115px"
        src="./../assets/1board_addLogo.svg"
        @click="openUploadDialog"
      />
      <div v-else class="image-wrapper mr-3">
        <b-img :src="parameters.logo.src" class="image-item" />
        <b-icon
          class="custom-badge clickable"
          icon="trash-fill"
          color="white"
          @click="removeLogo"
        />
      </div>
    </div>
    <div class="mt-5">
      <b-row class="no-gutters justify-content-between align-items-center mb-3">
        <b-col class="col-auto">
          <h4 class="font-weight-normal mb-0">
            {{ $t("page.parameters.categories") }}
          </h4>
        </b-col>
        <b-col class="col-auto">
          <b-button
            class="float-right mb-3"
            size="md"
            variant="secondary"
            @click="openCreateCategoryModal"
            v-b-modal.create-category-modal
          >
            {{ $t("page.parameters.newCategory") }}
          </b-button>
        </b-col>
      </b-row>
      <category-table
        :categories="categories"
        :fields="fields"
        :sortBy.sync="sortBy"
        :sortDesc.sync="sortDesc"
        @manage="editCategoryModal"
      />
      <create-category
        :state="createModalState"
        :callbackAfterCreate="retrieveCategoriesCallback"
        :defaultCategory="selectedCategory"
        @close="closeCreateCategoryModal"
      />
    </div>
  </div>
</template>

<script>
import FileUploader from "../../../console-components/BaseFileUploader.vue";
import CategoryTable from "../components/Parameters/CategoryTable.vue";
import CreateCategory from "../components/Parameters/CreateCategory.vue";

import uniqueId from "lodash/uniqueId";

export default {
  components: {
    FileUploader,
    CategoryTable,
    CreateCategory
  },
  data() {
    return {
      createModalState: false,
      selectedCategory: null,

      fields: [
        {
          key: "description",
          label: "name",
          sort: true,
          thStyle: { width: "25%" }
        },
        { key: "active", label: "status", thStyle: { width: "25%" } },
        { key: "details", label: "", thStyle: { width: "50%" } }
      ],
      sortBy: "description",
      sortDesc: false,
      parameters: {
        logo: null
      },
      invalidImage: false
    };
  },
  computed: {
    categories() {
      return this.$store.getters["parameters/getCategories"];
    },
    logo: {
      get() {
        return this.$store.getters["parameters/getLogo"];
      },
      set(value) {
        this.setParametersLogo(value);
      }
    }
  },
  methods: {
    openUploadDialog() {
      this.$refs.parameterLogo.openSelectFileWindow();
    },
    setLogo(file) {
      this.logo = file;
    },
    setParametersLogo(file) {
      if (!file[0]) return;
      this.parameters.logo = {
        _id: uniqueId("local_"),
        file: file[0],
        src: URL.createObjectURL(file[0])
      };
      setTimeout(() => {
        const img = document.querySelector(".image-item");
        if (img.naturalWidth !== 230 || img.naturalHeight !== 60) {
          this.invalidImage = true;
          this.parameters.logo = null;
          return;
        }
        this.invalidImage = false;
        this.$store.dispatch("parameters/updateLogo", {
          payload: this.parameters.logo
        });
      }, 100);
    },
    async removeLogo() {
      await this.$store.dispatch("parameters/updateLogo", {
        payload: null
      });
      this.parameters.logo = this.logo;
    },
    openCreateCategoryModal() {
      this.selectedCategory = null;
      this.createModalState = true;
    },
    editCategoryModal(item) {
      this.selectedCategory = item;
      this.createModalState = true;
    },
    closeCreateCategoryModal() {
      this.createModalState = false;
      this.selectedCategory = null;
    },
    async retrieveCategoriesCallback() {
      try {
        await this.$store.dispatch("parameters/retrieveCategories");
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
}
.image-wrapper {
  position: relative;
}
.image-item {
  border-radius: 10px;
  width: 115px;
  height: 100%;
}
.custom-badge {
  position: absolute;
  font-size: 20px;
  padding: 4px;
  bottom: 0px;
  right: -10px;
  background: var(--red);
  border-radius: 25px;
}
</style>
